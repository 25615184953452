import { useEffect, useState } from 'react';
import { Spinner } from 'assets/milo';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { stdnum } from 'stdnum';

import FormStyles from 'assets/styles/FormStyles.module.css';
import ADD_PROFILE_DETAILS, {
  ADD_PROFILE_DETAILS_MUTATION_NAME
} from 'graphql/mutations/ProfileDetailsMutation';
import {
  QUERY_CURRENT_USER,
  QueryCurrentUserResType,
  GET_CURRENT_USER_QUERY_NAME
} from 'graphql/queries/UserQueries';

import useQueryWithErrors from 'hooks/useQueryWithErrors';
import useMutationWithErrors from 'hooks/useMutationWithErrors';
import useMutationFormErrors from 'hooks/useMutationFormErrors';
import AddressForm from '../AddressForm';
import AddressFormValuesType from 'components/Forms/AddressForm/types/AddressFormValuesType';
import {
  QUERY_PAYMENT_INVITATION_BY_CURRENT_USER_ID
} from 'graphql/queries/PaymentInvitationQueries';
import { QUERY_CURRENT_ADDRESS_DETAILS, GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME } from 'graphql/queries/AddressDetailQueries'
import { QUERY_CURRENT_MEMBER_DETAILS, GET_CURRENT_MEMBER_DETAILS_QUERY_NAME } from 'graphql/queries/MemberDetailQueries'
import { useLazyQuery } from '@apollo/client';
import { nationalIdEnabled } from 'utils/helpers/flags'

import {
  Form,
  TextInput
} from '@justworkshr/milo-form'
import { Button } from '@justworkshr/milo-core';
import FormFieldWrapper from '../FormFieldWrapper';
import FormikFormField from '../FormikFormField'
import FieldGroup from '../FieldGroup'
import { getAddressFormSchema } from '../AddressForm/utils';
import { useAddressFormatter } from '../AddressForm/useAddressFormatter';
import PhoneNumberInput from 'components/Inputs/PhoneNumberInput/PhoneNumberInput';
import FormFooter from '../FormFooter';
import { COUNTRY_NATIONAL_ID_PLACEHOLDERS, COUNTRY_NATIONAL_ID_TYPES, NATIONAL_ID_COUNTRY_CODES } from './constants'
import { COUNTRIES_WITHOUT_POSTAL_CODE } from '../AddressForm/constants';

interface FormType extends AddressFormValuesType {
  member: {
    firstName: string,
    lastName: string,
    preferredName: string,
    pronouns: string,
    dateOfBirth: {
      month: number | '';
      day: number | '';
      year: number | '';
    },
    serviceProvided: string,
    website: string,
    phoneNumber: string,
  }
}

type Props = {
  onBack?: () => void,
  onCompleted?: () => void,
  submitButtonText?: string,
  backButtonText?: string,
  isProfileEditMode?: boolean
}

const PersonalInfoForm = ({ onBack, onCompleted, submitButtonText, backButtonText, isProfileEditMode }: Props) => {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<CountryCode>();
  const { data: userData } = useQueryWithErrors<QueryCurrentUserResType>(
    GET_CURRENT_USER_QUERY_NAME,
    QUERY_CURRENT_USER
  );

  const [getPaymentInvitationByCurrentUserId, { data: paymentInviteData }] = useLazyQuery(QUERY_PAYMENT_INVITATION_BY_CURRENT_USER_ID)

  // Fetch countryCode from address details first. If address wasn't previously completed/ saved, then query payment invitation
  const { data: addressData, loading: addressLoading } = useQueryWithErrors(
    GET_CURRENT_ADDRESS_DETAILS_QUERY_NAME,
    QUERY_CURRENT_ADDRESS_DETAILS,
    {
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.currentAddressDetails?.countryCode) {
          setCountryCode(data.currentAddressDetails.countryCode);
        } else {
          getPaymentInvitationByCurrentUserId();
        }
      }
    }
  );

  useEffect(() => {
    if (paymentInviteData) {
      const piCountryCode = paymentInviteData?.getPaymentInvitationByCurrentUserId?.details?.countryCode
      // Default country code to CA
      setCountryCode(piCountryCode ?? 'CA')
    }
  }, [paymentInviteData])

  const [addProfileDetails, { loading, error }] = useMutationWithErrors(
    ADD_PROFILE_DETAILS_MUTATION_NAME,
    ADD_PROFILE_DETAILS,
    { onCompleted }
  );

  const formErrors = useMutationFormErrors(error);

  const { orderedFields } = useAddressFormatter(countryCode || '');

  const schemaFn = getAddressFormSchema(countryCode, orderedFields)
  const addressSchema = schemaFn(t)

  const NOW = new Date();

  const { data: memberData, loading: memberLoading } = useQueryWithErrors(
    GET_CURRENT_MEMBER_DETAILS_QUERY_NAME,
    QUERY_CURRENT_MEMBER_DETAILS,
    {
      // Ensure we're getting fresh data
      fetchPolicy: 'no-cache'
    }
  )

  let dateOfBirth : any = {
    month: '',
    day: '',
    year: ''
  }

  if (memberData?.currentMemberDetails?.dateOfBirth) {
    const date = new Date(memberData?.currentMemberDetails?.dateOfBirth)
    dateOfBirth = {
      month: date.getUTCMonth() + 1, // Month is zero indexed
      day: date.getUTCDate(),
      year: date.getUTCFullYear()
    }
  }

  const formInitialValues: FormType = {
    member: {
      firstName: memberData?.currentMemberDetails?.firstName || '',
      lastName: memberData?.currentMemberDetails?.lastName || '',
      preferredName: memberData?.currentMemberDetails?.preferredName || '',
      pronouns: memberData?.currentMemberDetails?.pronouns || '',
      dateOfBirth: {
        ...dateOfBirth
      },
      website: memberData?.currentMemberDetails?.website || '',
      serviceProvided: memberData?.currentMemberDetails?.serviceProvided || '',
      phoneNumber: memberData?.currentMemberDetails?.phoneNumber || ''
    },
    country: {
      countryCode: addressData?.currentAddressDetails?.countryCode || countryCode || '',
      ...(nationalIdEnabled() && { nationalId: '' })
    },
    address: {
      countryCode: addressData?.currentAddressDetails?.countryCode || countryCode || '',
      streetLine1: addressData?.currentAddressDetails?.streetLine1 || '',
      streetLine2: addressData?.currentAddressDetails?.streetLine2 || '',
      city: addressData?.currentAddressDetails?.city || '',
      state: addressData?.currentAddressDetails?.state || '',
      postalCode: addressData?.currentAddressDetails?.postalCode || ''
    }
  }

  const formTranslation = (key: string) => t(`formValidations.${key}`)

  const isValidUrl = (value: string) => {
    if (value.slice(0, 4) !== 'http') value = 'https://' + value
    const urlRegEx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    return urlRegEx.test(value)
  }

  // The blocklist is a list of websites that can cause stripe errors
  const blocklist = new Set(['test.com', 'www.test.com', 'http://test.com', 'http://www.test.com', 'https://test.com', 'https://www.test.com'])

  const isStripeApproved = (value: string) => {
    if (blocklist.has(value.toLowerCase())) return false
    else return true
  }

  const FormSchema = Yup.object().shape({
    member: Yup.object().shape({
      firstName: Yup.string().required(formTranslation('required')),
      lastName: Yup.string().required(formTranslation('required')),
      preferredName: Yup.string(),
      pronouns: Yup.string(),
      dateOfBirth: Yup.object().shape({
        month: Yup.number()
          .max(12, formTranslation('invalidMonth'))
          .min(1, formTranslation('invalidMonth'))
          .required(formTranslation('required')),
        day: Yup.number()
          .max(31, formTranslation('invalidDay'))
          .min(1, formTranslation('invalidDay'))
          .required(formTranslation('required')),
        year: Yup.number()
          .max(NOW.getFullYear(), formTranslation('invalidYear'))
          .min(1900, formTranslation('invalidYear'))
          .required(formTranslation('required'))
      }).test(
        'date of birth',
        'Must be at least 18 years old',
        value => {
          if (!value.year || !value.month || !value.day) {
            return true
          }
          return new Date(value.year + 18, value.month - 1, value.day) <= new Date();
        }
      ),
      website: Yup.string()
        .test('valid url', formTranslation('invalidWebsite'), value => {
          if (value) {
            return isValidUrl(value) && isStripeApproved(value)
          } else {
            return true
          }
        }),
      serviceProvided: Yup.string()
        .max(500, formTranslation('serviceProvdedLength'))
        .min(3, formTranslation('serviceProvdedLength'))
        .required(formTranslation('serviceProvidedRequired')),
      phoneNumber: Yup.string()
        .required(formTranslation('required'))
        .test('valid', formTranslation('invalidPhoneNumber'), (value) => {
          const number = parsePhoneNumber(value || '', countryCode)
          return number?.isValid() || false
        })
    }),
    country: Yup.object().shape({
      countryCode: Yup.string(),
      nationalId: Yup.string().when('countryCode', {
        is: (value: string) => {
          if (nationalIdEnabled()) {
            return NATIONAL_ID_COUNTRY_CODES.includes(value)
          }
          return false
        },
        then: (schema) => {
          return schema.required(formTranslation('nationalId'))
            .test({
              name: 'valid national id',
              message: formTranslation('invalidNationalId'),
              test: (id, context) => {
                const countryCode = context.parent.countryCode

                if (countryCode === 'AE' && id.length !== 15) {
                  return context.createError({ message: 'Value must be 15 digits. Please try again.' })
                }
                if (countryCode === 'AR' && id.length !== 11) {
                  return context.createError({ message: 'Value must be 11 digits. Please try again.' })
                }
                if (countryCode === 'BD' && (id.length !== 10 && id.length !== 17)) {
                  return context.createError({ message: 'Value must be 10 or 17 digits. Please try again.' })
                }
                if (countryCode === 'CL' && id.length !== 9) {
                  return context.createError({ message: 'Value must be 9 digits. Please try again.' })
                }
                if (countryCode === 'CO' && (id.length < 7 || id.length > 10)) {
                  return context.createError({ message: 'Value must be 7 to 10 digits. Please try again.' })
                }
                if (countryCode === 'CR' && id.length !== 9) {
                  return context.createError({ message: 'Value must be 9 digits. Please try again.' })
                }
                if (countryCode === 'DO' && id.length !== 11) {
                  return context.createError({ message: 'Value must be 11 digits. Please try again.' })
                }
                if (countryCode === 'GT' && id.length !== 8) {
                  return context.createError({ message: 'Value must be 8 digits. Please try again.' })
                }
                if (countryCode === 'PE' && id.length !== 9) {
                  return context.createError({ message: 'Value must be 9 digits. Please try again.' })
                }
                if (countryCode === 'PY' && id.length !== 7) {
                  return context.createError({ message: 'Value must be 7 digits. Please try again.' })
                }
                if (countryCode === 'UY' && id.length !== 8) {
                  return context.createError({ message: 'Value must be 8 digits. Please try again.' })
                }

                const idType = COUNTRY_NATIONAL_ID_TYPES[countryCode]

                if (stdnum[countryCode]?.[idType]) {
                  return stdnum[countryCode][idType]?.validate(id).isValid;
                } else {
                  // Returning true if stdnum.validate() is skipped since above conditionals
                  // would handle error check for a given country
                  return true;
                }
              }
            })
        }
      })
    }),
    address: addressSchema
  });

  const onFormSubmit = ({ member, address, country }: FormType) => {
    const isWithoutPostalCode = COUNTRIES_WITHOUT_POSTAL_CODE.includes(country.countryCode)
    const dateOfBirth = `${member.dateOfBirth.year}-${member.dateOfBirth.month.toLocaleString(undefined, { minimumIntegerDigits: 2 })}-${member.dateOfBirth.day.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`
    addProfileDetails({
      variables: {
        ...member,
        dateOfBirth,
        ...address,
        ...(isWithoutPostalCode && { postalCode: '00000' }),
        // TODO: once backend is read, update graphql query to receive
        // this new nationalId field, and add other fields such as "ID type"
        ...(nationalIdEnabled() && { nationalId: country.nationalId })
      }
    });
  }

  const hasSpecialChars = (value: string) => {
    if (!value.length) {
      return false
    }
    const hasOnlyDigits = /^\d+$/.test(value)
    return !hasOnlyDigits
  }

  const stripSpecialChars = (num: string) => {
    return num.replace(/\D/g, '')
  }

  if (memberLoading || addressLoading) {
    return <Spinner variation='black'/>
  }

  const nationalIdRequired = nationalIdEnabled() && countryCode && NATIONAL_ID_COUNTRY_CODES.includes(countryCode)

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={FormSchema}
      validateOnBlur
      validateOnChange={true}
      onSubmit={onFormSubmit}
      enableReinitialize
      initialErrors={formErrors}
    >
      {({ handleBlur, handleChange, handleSubmit, values, setFieldValue, setFieldTouched }: FormikProps<FormType>) => (
        <Form onSubmit={handleSubmit}>
          <div className={FormStyles.formFieldContainer}>
            <div className={FormStyles.formHeaderContainer}>
              <h3>{t('profileForm.personalInfo')}</h3>
              <p className={FormStyles.formSubtitle}>{t('profileForm.personalInfoSubtitle')}</p>
            </div>

            <FormFieldWrapper>
              <FormikFormField label={t('common.email')} required>
                <TextInput name='emailInput' disabled value={userData?.currentUser?.emailAddress || ''} />
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='member.firstName'
                label={`${t('profileForm.firstName')} (${t('profileForm.givenName')})`}
                required
              >
                <TextInput
                  autoComplete='given-name'
                  id='member.firstName'
                  name='member.firstName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.firstName}
                  disabled={!!isProfileEditMode}
                />
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='member.lastName'
                label={`${t('profileForm.lastName')} (${t('profileForm.surname')})`}
                required
              >
                <TextInput
                  autoComplete='family-name'
                  id='member.lastName'
                  name='member.lastName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.lastName}
                  disabled={!!isProfileEditMode}
                />
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='member.preferredName'
                label={t('profileForm.preferredName')}
                message={t('profileForm.preferredNameDesc')}
                required={false}
              >
                <TextInput
                  autoComplete='nickname'
                  id='member.preferredName'
                  name='member.preferredName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.preferredName}
                />
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='member.pronouns'
                label={t('profileForm.pronouns')}
                message={t('profileForm.pronounsDesc')}
                required={false}
              >
                <TextInput
                  placeholder={t('profileForm.pronounsPlaceholder')}
                  id='member.pronouns'
                  name='member.pronouns'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.pronouns}
                />
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper group>
              <FormikFormField
                label={t('profileForm.dateOfBirth')}
                required
              >
                <FieldGroup>
                  <FormikFormField name='member.dateOfBirth.year' label={t('profileForm.year')} required>
                    <TextInput
                      autoComplete='bday-year'
                      placeholder={t('profileForm.dateOfBirthYear')}
                      id='member.dateOfBirth.year'
                      name='member.dateOfBirth.year'
                      type='number'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.member.dateOfBirth.year.toString()}
                      disabled={!!isProfileEditMode}
                    />
                  </FormikFormField>
                  <FormikFormField name='member.dateOfBirth.month' label={t('profileForm.month')} required>
                    <TextInput
                      autoComplete='bday-month'
                      placeholder={t('profileForm.dateOfBirthMonth')}
                      id='member.dateOfBirth.month'
                      name='member.dateOfBirth.month'
                      type='number'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.member.dateOfBirth.month.toString()}
                      disabled={!!isProfileEditMode}
                    />
                  </FormikFormField>
                  <FormikFormField name='member.dateOfBirth.day' label={t('profileForm.day')} required>
                    <TextInput
                      autoComplete='bday-day'
                      placeholder={t('profileForm.dateOfBirthDay')}
                      id='member.dateOfBirth.day'
                      name='member.dateOfBirth.day'
                      type='number'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.member.dateOfBirth.day.toString()}
                      disabled={!!isProfileEditMode}
                    />
                  </FormikFormField>
                </FieldGroup>
              </FormikFormField>
            </FormFieldWrapper>

            <FormFieldWrapper>
              <FormikFormField
                name='member.website'
                label={t('profileForm.website')}
                required={false}
              >
                <TextInput
                  autoComplete='url'
                  id='member.website'
                  name='member.website'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.website}
                />
              </FormikFormField>
            </FormFieldWrapper>
          </div>

          <div className={FormStyles.formFieldContainer}>
            <div className={FormStyles.formHeaderContainer}>
              <h3>{t('profileForm.permAddress')}</h3>
              <p className={FormStyles.formSubtitle}>{t('profileForm.permAddressSubtitle')}</p>
            </div>

            {countryCode ? (
              <>
                <AddressForm
                  isProfileEditMode={isProfileEditMode}
                  handleCountryChange={(value: CountryCode) => {
                    setCountryCode(value)
                    // explicitly update country.countryCode so that our conditional
                    // nationalId Yup validation is enabled. Also prevents having to update
                    // values.address.countryCode in every country's address form component
                    // TODO: Once dynamic address form is implemented, set country.countryCode
                    // to be the default instead of address.countryCode. Right now `values` is
                    // passed to every single country address form component, trying to avoid
                    // updating tons of files when they're about to change
                    setFieldValue('country.countryCode', value)
                    setFieldValue('address.state', '')
                    setFieldTouched('address.state', false)
                  }}
                />

                <FormFieldWrapper>
                  <FormikFormField label={t('profileForm.phoneNumber')} name='member.phoneNumber' required>
                    <PhoneNumberInput
                      id='member.phoneNumber'
                      name='member.phoneNumber'
                      countryCode={countryCode}
                      value={values.member.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormikFormField>
                </FormFieldWrapper>
              </>
            ) : null}
          </div>

          {nationalIdRequired ? (
            <div className={FormStyles.formFieldContainer}>
              <div className={FormStyles.formHeaderContainer}>
                <h3>{t('profileForm.nationalId')}</h3>
              </div>

              <FormFieldWrapper>
                <FormikFormField
                  name='country.nationalId'
                  label={t('profileForm.nationalId')}
                  message={t('profileForm.nationalIdDesc')}
                  required={nationalIdRequired}
                >
                  <TextInput
                    id='country.nationalId'
                    name='country.nationalId'
                    type='text'
                    onChange={(e) => {
                      if (hasSpecialChars(e.target.value)) {
                        setFieldValue('country.nationalId', stripSpecialChars(e.target.value))
                      } else {
                        handleChange(e)
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.country.nationalId}
                    placeholder={COUNTRY_NATIONAL_ID_PLACEHOLDERS[countryCode]}
                  />
                </FormikFormField>
              </FormFieldWrapper>
            </div>
          ) : null}

          <div className={FormStyles.formFieldContainer}>
            <div className={FormStyles.formHeaderContainer}>
              <h3>{t('profileForm.serviceProvided')}</h3>
            </div>

            <FormFieldWrapper>
              <FormikFormField
                name='member.serviceProvided'
                label={t('profileForm.serviceProvided')}
                message={t('profileForm.serviceProvidedDesc')}
                required
              >
                <TextInput
                  id='member.serviceProvided'
                  name='member.serviceProvided'
                  type='text'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.member.serviceProvided}
                  disabled={!!isProfileEditMode}
                />
              </FormikFormField>
            </FormFieldWrapper>
         </div>

          <FormFooter>
            {
              onBack && (
                <aside>
                  <Button mode='secondary' variant='outlined' onClick={onBack}>
                    {backButtonText || t('common.back')}
                  </Button>
                </aside>
              )
            }

            <Button loading={loading} type='submit'>
              {submitButtonText || t('common.submit')}
            </Button>
          </FormFooter>
        </Form>
      )}
    </Formik>
  )
}

export default PersonalInfoForm;
